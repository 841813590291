<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between align-items-center mb-1">
        <h1 class="font-weight-bolder mb-0">
          {{ $t('sells') }}
        </h1>
      </b-col>
    </b-row>
    <b-row v-if="quotes.length > 0">
      <b-col>
        <quotes-table :quotes="quotes" />
      </b-col>
    </b-row>
    <b-row v-if="quotes.length === 0">
      <b-col>
        <h4 class="display-4">
          No hay Ventas
        </h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import QuotesTable from '@/components/pricing/QuotesTable.vue'

export default {
  components: {
    QuotesTable,
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('quotes', ['quotes']),
  },

  created() {
    this.getQuotesWon({ winnerBranch: this.$route.params.id })
  },

  destroyed() {
    this.clearQuotes()
  },

  methods: {
    ...mapActions('quotes', ['getBiddingQuotes', 'getQuotesWon']),
    ...mapMutations('quotes', {
      clearQuotes: 'CLEAR_QUOTES',
    }),
  },
}
</script>

<style></style>
